<template>
  <div>
    <VuePhoneNumberInput
      v-model="computedInputVal"
      default-country-code="US"
      :preferred-countries="['US']"
      required
      @update="onUpdate"
      :class="{'input-error': errorMessage}"
    />
    <span class="err-message" v-if="errorMessage">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: { VuePhoneNumberInput },
  props: {
    value: { type: [String, Number], default: null }
  },
  data() {
    return {
      errorMessage: ''
    };
  },
  computed: {
    computedInputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    ...mapActions("auth", {
      checkPhone: "onCheckPhone"
    }),
    onUpdate(payload) {
      this.$emit("update", payload);
      if (!payload.isValid) {
        this.errorMessage = 'Invalid phone number';
      } else if (payload.nationalNumber.length !== 10 || payload.nationalNumber.length > 10) {
        this.errorMessage = 'Phone number must be exactly 10 digits';
      } else {
          let params = {
        phone: payload.nationalNumber
      };
      this.checkPhone(params)
        .then(() => {
          this.loading = false;
          this.errorMessage =  "";
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.errorMessage  = error.response.data.message;
          }
        });
      }
    
    },
  }
};
</script>

<style scoped>
.vue-phone-number-input :deep(.country-selector__label) {
  display: none;
}
.vue-phone-number-input :deep(.input-tel__label) {
  display: none;
}

div :deep(.input-tel.has-hint .input-tel__input) {
  padding-top: 0;
}
div :deep(.input-tel.has-value .input-tel__input) {
  padding-top: 0;
}

div :deep(.country-selector.has-value .country-selector__input) {
  padding-top: 0;
}
div :deep(.country-selector__country-flag) {
  top: 14px;
}
.err-message {
  color: red;
  font-size: 12px;
}

.input-error .input-tel__input {
  border-color: red !important;
}
</style>
